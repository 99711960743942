import './App.css';
import {useEffect, useRef, useState} from "react";
import {addToLog, generateNewKey, getUser, increaseLookups, searchUser, validateKey} from "./ApiService";
import {Button, Card, Center, Checkbox, Group, Input, Modal, Table, Title} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {notifications} from "@mantine/notifications";
import {DatePickerInput} from "@mantine/dates";

function App() {
    const [userKey, setUserKey] = useState(null);
    const [user, setUser] = useState(null);
    const [data, setData] = useState(null);
    const [logs, setLogs] = useState(null);
    const keyInput = useRef('');
    const userInput = useRef('');
    const [opened, {open, close}] = useDisclosure(false);
    const [dataOpened, dataFunctions] = useDisclosure(false);
    const [generateOpened, generateFunctions] = useDisclosure(false);
    const [blockOpened, blockFunctions] = useDisclosure(false);
    const [blockNickname, setBlockNickname] = useState('');
    const [logsOpened, logsFunctions] = useDisclosure(false);
    const [date, setDate] = useState(new Date());
    const [admin, setAdmin] = useState(false);
    const [generatedKey, setGeneratedKey] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [searching, setSearching] = useState(false);

    const handleGenerateNewKey = async () => {
        generateFunctions.open();
    }

    const handleBlockNickname = async () => {
        blockFunctions.open();
    }
    const handleSearchUser = async () => {
        if (searching) {
            notifications.show({
                title: 'Already searching',
                color: 'red',
                autoClose: 5000
            })
            return;
        }
        setSearching(true);
        const user = await searchUser(userKey, userInput.current.value);
        if (!user.valid) {
            notifications.show({
                title: user.error,
                color: 'red',
                autoClose: 5000
            })
            const lookups = increaseLookups(userKey);
            if (lookups.valid) {
                const newUser = {...user};
                newUser.lookups = lookups.lookups;
                setUser(user => newUser);
            }
        } else if (user.http_response_header === "HTTP/1.1 429 Too Many Requests") {
            notifications.show({
                title: 'Too many requests to api. Try again later.',
                color: 'red',
                autoClose: 5000
            })
        } else if (user.result.length === 0) {
            notifications.show({
                title: 'User not found',
                color: 'red',
                autoClose: 5000
            })
        } else {
            const newData = [];
            user.result.forEach((file) => {
                file.ip.forEach((ip) => {
                    newData.push([file.file, ip, file.username])
                })
            });
            setData(newData);
            dataFunctions.open();
            notifications.show({
                title: 'User found',
                color: 'yellow',
                autoClose: 5000
            })
            const lookups = increaseLookups(userKey);
            if (lookups.valid) {
                const newUser = {...user};
                newUser.lookups = lookups.lookups;
                setUser(user => newUser);
            }
        }
        setSearching(false);
    }

    const handleLogs = async () => {
        const response = await fetch('/logs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({key: userKey})
        });
        const data = await response.json();
        if (data.valid) {
            setLogs(data.logs);
            logsFunctions.open();
        } else {
            notifications.show({
                title: 'Error',
                color: 'red',
                autoClose: 5000
            })
        }
    }

    const handleSubmitKey = async () => {
        const key = keyInput.current.value;
        const valid = await validateKey(key);
        if (valid) {
            localStorage.setItem('userKey', key);
            setUserKey(key);
            const user = await getUser(key);
            if (!user.valid) {
                localStorage.removeItem('userKey');
                setUserKey(null);
                return;
            }
            setUser(user);
            notifications.show({
                title: 'Logged in',
                yellow: false,
                color: 'yellow',
                autoClose: 1500
            })
            await addToLog(key);
        } else {
            notifications.show({
                title: 'Invalid key',
                withCloseButton: false,
                color: 'red',
                autoClose: 1500
            })
        }
    }

    useEffect(() => {
        async function validate() {
            const key = localStorage.getItem('userKey');
            if (key == null) return;
            const valid = await validateKey(key);
            if (!valid) {
                localStorage.removeItem('userKey');
                setUserKey(null);
            } else {
                setUserKey(key);
                const user = await getUser(key);
                if (!user.valid) {
                    localStorage.removeItem('userKey');
                    setUserKey(null);
                    return;
                }
                setUser(user);
            }
        }

        validate().then(r => console.log('validated'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="App">
            <Modal opened={opened} onClose={close}
                   title="Rules" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <div style={{
                    textAlign: 'center'
                }}>
                    <p style={{margin: "5px 0px"}}>1. You can't share your key with anyone.</p>
                    <p style={{margin: "5px 0px"}}>2. You can't use this api to make your own database.</p>
                </div>
            </Modal>
            <Modal opened={blockOpened} onClose={blockFunctions.close}
                   title="Add nickname to blacklist" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <Input.Wrapper mb={20} label="Nickname" withAsterisk description=""
                               error="">
                    <Input style={{
                        borderColor: "#000000",
                    }} placeholder="Nickname" value={blockNickname} onChange={(event) => {
                        setBlockNickname(event.currentTarget.value);
                    }}/>
                </Input.Wrapper>
                <Center>
                    <Button style={{width: '100px'}} onClick={async () => {
                        const response = await fetch('/blockNickname', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({key: userKey, nickname: blockNickname})
                        });
                        const data = await response.json();
                        if (data.valid) {
                            notifications.show({
                                title: 'Nickname blocked',
                                withCloseButton: false,
                                color: 'yellow',
                                autoClose: 1500
                            })
                        } else {
                            notifications.show({
                                title: 'Error',
                                withCloseButton: false,
                                color: 'red',
                                autoClose: 1500
                            })
                        }
                    }} variant="outline"
                            color="black">Block</Button>
                </Center>
            </Modal>
            <Modal size="xl" opened={logsOpened} onClose={logsFunctions.close}
                   title="Logs" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                {logs == null ? (
                    <h1>Loading...</h1>
                ) : (
                    <Table striped highlightOnHover withRowBorders={false}>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>key</Table.Th>
                                <Table.Th>ip</Table.Th>
                                <Table.Th>date</Table.Th>
                                <Table.Th>actions</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {logs.map((row) => (
                                <Table.Tr key={row[0]}>
                                    <Table.Td>{row[1]}</Table.Td>
                                    <Table.Td>{row[2]}</Table.Td>
                                    <Table.Td>{row[3]}</Table.Td>
                                    <Table.Td>
                                        {/*ban*/}
                                        <Button onClick={async () => {
                                            const response = await fetch('/ban', {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({key: userKey, keyToBan: row[1]})
                                            });
                                            const data = await response.json();
                                            if (data.valid) {
                                                notifications.show({
                                                    title: 'Banned',
                                                    withCloseButton: false,
                                                    color: 'yellow',
                                                    autoClose: 1500
                                                })
                                            } else {
                                                notifications.show({
                                                    title: 'Error',
                                                    withCloseButton: false,
                                                    color: 'red',
                                                    autoClose: 1500
                                                })
                                            }
                                        }} variant="outline" color="red">Ban</Button>
                                        {/*delete*/}
                                    </Table.Td>
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                    </Table>
                )}
            </Modal>
            <Modal opened={generateOpened} onClose={() => {
                generateFunctions.close();
                setGeneratedKey(null);
            }}
                   title="Generate new key" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <Input.Wrapper mb={20} label="Expire" withAsterisk description=""
                               error="">
                    <DatePickerInput
                        placeholder="Pick date"
                        value={date}
                        onChange={setDate}
                    />
                </Input.Wrapper>
                <Checkbox
                    label="Admin?"
                    checked={admin}
                    onChange={(event) => setAdmin(event.currentTarget.checked)}
                />
                <Center>
                    <Button style={{width: '100px'}} onClick={async () => {
                        setGenerating(true);
                        const response = await generateNewKey(userKey, date, admin);
                        setGenerating(false);
                        if (response.valid) {
                            notifications.show({
                                title: 'Key generated',
                                withCloseButton: false,
                                color: 'yellow',
                                autoClose: 1500
                            })
                            setGeneratedKey(response.key);
                        } else {
                            notifications.show({
                                title: 'Error',
                                withCloseButton: false,
                                color: 'red',
                                autoClose: 1500
                            })
                        }
                    }} variant="outline"
                            color="black">Generate</Button>
                </Center>
                {generating && (
                    <h1>Generating...</h1>
                )}
                {generatedKey && (
                    <h1>Generated key: {generatedKey}</h1>
                )}
            </Modal>
            <Modal opened={dataOpened} onClose={dataFunctions.close} centered withCloseButton={false}
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                {data == null ? (
                    <h1>Loading...</h1>
                ) : (
                    <div>
                        <Table striped highlightOnHover withRowBorders={false}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>user</Table.Th>
                                    <Table.Th>address</Table.Th>
                                    <Table.Th>src</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {data.map((row, idx) => (
                                    <Table.Tr key={idx}>
                                        <Table.Td>{row[2]}</Table.Td>
                                        <Table.Td style={{
                                            cursor: "pointer"
                                        }} onClick={() => {
                                            navigator.clipboard.writeText(row[1]);
                                            notifications.show({
                                                title: 'Copied!',
                                                withCloseButton: false,
                                                color: 'yellow',
                                                autoClose: 1500
                                            })
                                        }}>{row[1]}</Table.Td>
                                        <Table.Td>{row[0]}</Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </div>
                )}
            </Modal>
            <Title
                className="title"
                style={{
                    fontSize: '80px',
                }} order={1}>lightleaks.xyz</Title>
            {userKey == null ? (
                <>
                    <Card
                        key={"login"}
                        shadow="sm"
                        style={{width: 400, backgroundColor: "rgba(26,26,26,0.5)", border: "1px solid #000"}}
                        padding={20}
                        mt={40}
                    >
                        <Input.Wrapper mb={20} label="Key" description=""
                                       error="">
                            <Input style={{
                                borderColor: "#000000",
                            }} placeholder="(Example: rDYA4h7$eDESvl@Ds84X)" ref={keyInput}/>
                        </Input.Wrapper>
                        <Center>
                            <Button style={{width: '100px'}} onClick={handleSubmitKey} variant="outline"
                                    color="black">Login</Button>
                        </Center>
                    </Card>
                </>
            ) : (
                <>
                    <Card
                        key={"search"}
                        shadow="sm"
                        style={{width: 400, backgroundColor: "rgba(26,26,26,0.5)", border: "1px solid #000"}}
                        padding={20}
                        mt={40}
                    >
                        <Input.Wrapper mb={20} label="Username" description=""
                                       error="">
                            <Input style={{
                                borderColor: "#000000",
                            }} placeholder="(example: TrombaPowietrzna)" ref={userInput}/>
                        </Input.Wrapper>
                        <Center>
                            <Button style={{width: '100px', fontWeight: "bold"}} onClick={handleSearchUser} variant="outline"
                                    color="black">Find</Button>
                        </Center>
                        <Card.Section>
                            {user == null ? (
                                <h1 style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}>Loading...</h1>
                            ) : (
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                    padding: "10px 0px 0px 0px"
                                }}>
                                    <p style={{width: "calc(100% / 3)", textAlign: "center", fontWeight: "bold"}}>Rank<br/><span
                                        style={{color: "#000000", fontWeight: "bold"}}>{user.role}</span></p>
                                    <p style={{width: "calc(100% / 3)", textAlign: "center", fontWeight: "bold"}}>Expiry
                                        time <br/> {user.expires}</p>
                                    <p style={{
                                        width: "calc(100% / 3)",
                                        textAlign: "center", fontWeight: "bold"
                                    }}>Searches<br/>{user.lookups}
                                    </p>
                                </div>
                            )}
                        </Card.Section>
                    </Card>

                    {user && user.role === "Admin" && (
                        <Card
                            shadow="sm"
                            mt={20}
                            style={{width: 400, backgroundColor: "rgba(26,26,26,0.5)", border: "1px solid #000"}}
                            className={'price-list'}
                        >
                            <Button style={{width: '200px'}} onClick={handleGenerateNewKey} variant="outline"
                                    color="black">Generate new key</Button>
                            <Button mt={10} style={{width: '200px'}} onClick={handleBlockNickname} variant="outline"
                                    color="black">Block nickname</Button>
                        </Card>
                    )}

                    <Group mt={10} mb={15} position="center">
                        {user && user.role === "Admin" && (
                            <Button onClick={handleLogs} variant="outline" color="gray">Logs</Button>
                        )}
                        <Button onClick={open} variant="outline" color="gray">Rules</Button>
                        <Button onClick={() => {
                            localStorage.removeItem('userKey');
                            notifications.show({
                                title: 'Logged out',
                                withCloseButton: false,
                                color: 'red',
                                autoClose: 1500
                            })
                            setUserKey(null);
                        }} variant="outline" color="gray">Logout</Button>
                    </Group>
                </>
            )}
            <a href="https://discord.gg/lightleaks" target="_blank">
                <svg className={"discord"} style={{
                    marginTop: '20px',
                }}
                     width="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                    <path className={"line"} fill="#fff"
                          d="M247.29492,169.80862,213.32129,56.56252A15.953,15.953,0,0,0,204.084,46.35744l-.53759.16309.54931-.1919a185.84371,185.84371,0,0,0-34.53515-10.30712,8.00026,8.00026,0,1,0-3.12012,15.69336q6.72656,1.3374,13.18457,3.14746a7.97792,7.97792,0,0,1-4.46045,15.07714,188.85309,188.85309,0,0,0-93.26074-.18359,7.99484,7.99484,0,0,1-5.56592-14.87305l.03418.01172q6.4541-1.80762,13.20068-3.15185a8.00026,8.00026,0,0,0-3.12011-15.69336A185.88539,185.88539,0,0,0,51.917,46.35647a15.95771,15.95771,0,0,0-9.23828,10.20507L8.70508,169.80862a16.09028,16.09028,0,0,0,4.88672,16.72656c.95459.83008,1.9331,1.645,2.92529,2.45166.02344.019.04346.041.06689.06006,16.23047,13.166,37.50342,23.22461,61.51856,29.08691a7.99977,7.99977,0,0,0,3.79492-15.543,160.72734,160.72734,0,0,1-31.33789-11.0664l.06592.00488a7.99746,7.99746,0,0,1,8.59912-13.24219C78.21,186.74367,102.063,192,128,192s49.80664-5.26269,68.792-13.71924a7.997,7.997,0,0,1,8.58252,13.24854l.06592-.00488a160.72734,160.72734,0,0,1-31.33789,11.0664,7.99978,7.99978,0,1,0,3.79492,15.543c24.01514-5.8623,45.28809-15.9209,61.51856-29.08691.02441-.01953.04492-.042.06884-.062h.00049c.99121-.80615,1.96973-1.62109,2.92383-2.45068A16.09062,16.09062,0,0,0,247.29492,169.80862ZM96,156a12,12,0,1,1,12-12A12,12,0,0,1,96,156Zm64,0a12,12,0,1,1,12-12A12,12,0,0,1,160,156Z"></path>
                </svg>
            </a>
        </div>
    );
}

export default App;
