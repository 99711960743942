import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MantineProvider defaultColorScheme="dark" theme={{
        primaryColor: 'bright-pink',
        colors: {
            'bright-pink': ['#1f1f1f', '#151515', '#313131', '#3d3d3d', '#1f1f1f', '#151515', '#313131', '#3d3d3d', '#1f1f1f', '#1f1f1f'],
        },
    }}>
        <Notifications position="top-left"/>
        <App/>
    </MantineProvider>
);